import { DynamicPageTextBlockModel } from "./dynamicPageTextBlockModel"
import { DynamicPageTileModel } from "./dynamicPageTileModel"

export const getDynamicPageComponentsFromStrapi = (components) => {
  return [
    ...(components.dynamic_page_text_blocks || []).map((text_block) => {
      return new DynamicPageTextBlockModel(text_block)
    }),
    ...(components.dynamic_page_tiles || []).map((tile) => {
      return new DynamicPageTileModel(tile)
    }),
  ]
}

import { graphql } from "gatsby"
import React from "react"

import GetDynamicPageComponent from "../components/dynamic-page-components/get-dynamic-page-component/get-dynamic-page-component"
import PlainTextPageLayout from "../components/layout/plain-text-page-layout/plain-text-page-layout"
import PageHeader from "../components/page-header/page-header"
import SEO from "../components/seo"
import SideNavView from "../components/views/side-nav-view/side-nav-view"
import { useTranslation } from "../i18n/translate"
import { getDynamicPageComponentsFromStrapi } from "../model/dynamic-pages/getDynamicPageComponentsFromStrapi"

const DynamicPage = ({ pageContext, data, location }) => {
  const { t } = useTranslation()
  const { dynamicPage } = pageContext

  const dynamicPageComponents = getDynamicPageComponentsFromStrapi(
    data.strapiDynamicPage.page_components,
  )

  const hasNav = !!dynamicPage.nav_item

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t(dynamicPage.title.key)}
        descriptionTranslatedString={t(dynamicPage.description.key)}
      />
      {hasNav ? (
        <SideNavView
          titleTranslatedString={t(dynamicPage.title.key)}
          pathName={location.pathname}
        >
          <div className="_fp-grid _fp-grid--gap">
            {dynamicPageComponents.map((component, index) => (
              <div key={index} className="_fp-col-12">
                <GetDynamicPageComponent component={component} />
              </div>
            ))}
          </div>
        </SideNavView>
      ) : (
        <PlainTextPageLayout
          header={
            <PageHeader
              levelThreeTitle={t(dynamicPage.title.key)}
              levelTwoTitle={t(dynamicPage.title.key)}
              container={"text"}
            ></PageHeader>
          }
        >
          <div className="_fp-grid _fp-grid--gap">
            {dynamicPageComponents.map((component, index) => (
              <div key={index} className="_fp-col-12">
                <GetDynamicPageComponent component={component} />
              </div>
            ))}
          </div>
        </PlainTextPageLayout>
      )}
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query ($dynamicPageId: String) {
    strapiDynamicPage(id: { eq: $dynamicPageId }) {
      id
      page_components {
        id
        dynamic_page_tiles {
          children {
            id
            dynamic_page_text_blocks {
              text {
                key
              }
              image_resources {
                description {
                  key
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
          linkPath
          title {
            key
          }
          linkText {
            key
          }
        }
        dynamic_page_text_blocks {
          text {
            key
          }
          image_resources {
            description {
              key
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
      url
      description {
        key
      }
      title {
        key
      }
    }
  }
`

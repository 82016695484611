import { DynamicPageBaseComponentModel } from "./dynamicPageBaseComponentModel"
import { getDynamicPageComponentsFromStrapi } from "./getDynamicPageComponentsFromStrapi"

export class DynamicPageTileModel extends DynamicPageBaseComponentModel {
  constructor(c = {}) {
    super(c, "Tile")
    this.title = c.title
    this.linkPath = c.linkPath
    this.linkText = c.linkText
    this.children = getDynamicPageComponentsFromStrapi(c.children)
  }
}

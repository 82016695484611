import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { DynamicPageTileModel } from "../../../model/dynamic-pages/dynamicPageTileModel"
import Tile from "../../tile/tile"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import GetDynamicPageComponent from "../get-dynamic-page-component/get-dynamic-page-component"

const DynamicPageTile = ({ data }) => {
  const { t } = useTranslation()

  return (
    <>
      <Tile
        linkPath={data.linkPath}
        linkTextTranslatedString={t(data.linkText.key)}
        titleTranslatedString={t(data.title.key)}
      >
        {data.children &&
          data.children.length > 0 &&
          data.children.map((component, index) => {
            return (
              <div key={index}>
                <GetDynamicPageComponent component={component} />
                <TileContentSpacer />
              </div>
            )
          })}
      </Tile>
    </>
  )
}

DynamicPageTile.propTypes = {
  data: PropTypes.instanceOf(DynamicPageTileModel),
}

export default DynamicPageTile

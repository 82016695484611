import PropTypes from "prop-types"
import React from "react"

import { GalleryPictureItem } from "../../gallery/model/gallery-item"
import DynamicPageTile from "../dynamic-page-tile/dynamic-page-tile"
import TextWithOrWithoutGallery from "../text-with-or-without-gallery/text-with-or-without-gallery"

const GetDynamicPageComponent = ({ component }) => {
  switch (component.type) {
    case "Tile":
      return <DynamicPageTile data={component} />
    case "TextBlock":
      const gallery = []
      if (component.image_resources) {
        for (let { image, description } of component.image_resources) {
          gallery.push(
            new GalleryPictureItem({
              gatsbyFluidImage: image.localFile.childImageSharp.gatsbyImageData,
              descriptionTranslationKey: description?.key,
            }),
          )
        }
      }
      return (
        <TextWithOrWithoutGallery
          gallery={gallery}
          textTranslationKey={component.text.key}
        />
      )
    default:
      throw new Error(`Invalid Type: ${component.type}`)
  }
}
GetDynamicPageComponent.propTypes = {
  component: PropTypes.shape({
    type: PropTypes.oneOf(["Tile", "TextBlock"]).isRequired,
  }),
}

export default GetDynamicPageComponent

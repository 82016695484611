import React from "react"

class PlainTextPageLayout extends React.Component {
  render() {
    const { children, header } = this.props

    return (
      <>
        {header}
        <div className="_fp-global-container _fp-global-text-container">
          <div className="_fp-global-regular-font-formatting ">{children}</div>
        </div>
      </>
    )
  }
}

export default PlainTextPageLayout
